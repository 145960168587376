$laz-blue: rgb(67, 107, 251);
$laz-sky-blue: rgb(119, 237, 253);
$laz-gray: rgb(226, 226, 226);
$laz-charcoal: rgb(182, 182, 182);
$laz-background: rgb(248, 248, 248);
$laz-dark-gray: rgb(107, 107, 107);
$laz-light-gray: rgb(216, 216, 216);
$laz-btn-hover: rgb(40, 40, 40);
$laz-green: rgb(0, 225, 132);
$button: #8ee9ff;
$percent: #b4b5ff;

* {
  font-family: 'Work Sans', sans-serif;
}

body {
  margin: 0;
  background-color: black;
  // overscroll-behavior: contain;
}

.error-page {
  background-color: $laz-sky-blue;
  height: 100%;
}

.text-align-center {
  text-align: center;
}

.svg-48 {
  margin: -10px 0 0 -10px;
  cursor: pointer;
}

.align-center {
  align-items: center;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.align-end {
  align-items: flex-end;
}

.margin-top-0 {
  margin-top: 0;
}

.height-100 {
  height: 100%;
}

.align-start {
  align-items: flex-start;
}

.width-2-3 {
  width: 66%;
}

.cart-content {
  padding: 2em 0;
  font-size: 1.25em;
}

// .width-1-3 {
//   width: 33%;
// }

#root {
  width: 100vw;
  background-color: black;
}

.App {
  width: 100%;
  height: 100%;
  max-width: 480px;
  margin: 0 auto;
  background-color: white;
  position: relative;
}

.image-upload-container {
  background-color: white;
}

.test-set {
  margin: 1em 0 0 0;
}

.test-set-header {
  margin: 0 0 5px;
  border: 1px solid $laz-dark-gray;
  height: 3em;
  padding: 1em;
  cursor: pointer;
}

.photo-set-container {
  overflow: scroll;
}

header {
  background-color: $laz-sky-blue;
  padding: 1em;
  box-sizing: border-box;
}

#mobitest {
  height: 1em;
  border: 2px solid black;
  border-radius: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
}

.display-none {
  display: none;
}

#mobitest > p {
  margin: 0;
}

.collage {
  position: relative;
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 6em auto;
}

.collage-header {
  background-color: $laz-sky-blue;
  padding: 1em;
}

.collage-header .circle {
  cursor: pointer;
}

h1, h2 {
  font-weight: normal;
}

.container1 {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
}

#result-page {
  grid-template-rows: 1fr 1fr min-content;
}

.patient-form-page {
  height: 100%;
  display: grid;
  grid-template-rows: min-content auto min-content
}

.patient-form-page > form {
  margin: 1em;
}

.patient-form-page label {
  margin-bottom: 5px;
}

.patient-form-page input {
  border: 1px solid black;
  border-radius: 0 !important;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.25em;
  margin-bottom: 1em;
  margin-top: 6px;
}

#sex-select {
  height: 2.5em;
  border: 1px solid black;
  -webkit-appearance: none;
  -webkit-border-radius: 0px;
  border-radius: 0 !important;
  padding: 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 1.25em;
  margin-bottom: 1em;
  margin-top: 6px;
  cursor: pointer;
}

.birthday #day {
  width: calc(33% - 12px);
  margin: 6px 6px;
}

.birthday #month {
  width: calc(33% - 12px);
  margin: 6px 6px 0 0;
}

.birthday #year {
  width: calc(33% - 12px);
  margin: 6px 0 0 6px;
}

.patient-form-page input:focus {
  outline-color: $laz-sky-blue;
}

#sex-select:focus {
  outline-color: $laz-sky-blue;
}

#checklist-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: min-content auto min-content;
}

.height-4em {
  height: 4em;
}

#instructions {
  margin: 0 1em 1em;
  padding: 1em;
  background-color: rgb(244, 244, 244);
  border: 1px solid black;
}

#checklist-container h1 {
  margin: 1em;
}

#checklist-container > h2 {
  text-align: center;
}

#checklist-container .warning-div {
  margin: 0 1em 3em;
}

// #instructions-container {
//   height: calc(100% - 3em);
// }

.container1 > header, .container1 > main {
  height: 100%;
}

// .result-main {
//   display: grid;
//   grid-template-rows: 1fr 1fr;
// }

.flex {
  display: flex;
}

.dm {
  height: 4em;
  width: auto;
  margin-right: 1em;
}

.check-item {
  // height: 4em;
  align-items: center;
}

.checklist {
  margin: 1em 0;
  background-color: rgb(244, 244, 244);
  padding: 2em 1em;
}



.margin-1em {
  margin: 1em;
}

.side-margin-2em {
  margin: 0 2em;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.flex-end {
  justify-content: flex-end;
}

#check-list-header {
  padding: 2em 1em !important;
}

.checklist-header-div {
  padding: 1em 1em 0;
  margin: 1em;
  height: 8em;
  border: 1px solid $laz-charcoal;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1em 1fr;
  grid-template-areas:
  "a b"
  "c c";
}

.a {
  grid-area: a;
}

.b {
  grid-area: b;
  display: flex;
  justify-content: flex-end;
}

.c {
  grid-area: c;
}

.justify-center {
  justify-content: center;
}

footer {
  display: flex;
  justify-content: center;
  margin-bottom: 1em;
}

.white {
  color: white;
}

footer > .btn-div {
  background-color: black;
  color: white;
  margin: 0 1em;
  width: 100%;
  height: 4em;
}

// #lazarus-logo {
//   margin-left: -15px;
// }

.btn-div > p {
  font-size: 1.5em;
}

.line-height {
  line-height: 1.5em;
}

.bold {
  font-weight: bold;
}

.font-1-5-em {
  font-size: 1.5em;
}

.margin-top-1em {
  margin: 1em 0;
}

.testSet-container {
  height: 100%;
  width: 100%;
  display: grid;
  grid-template-rows: 4.5em 7em min-content min-content min-content auto;
  overflow: scroll;
}

.carousel-plus {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 5em;
  width: auto;
}

.carousel-container {
  overflow: scroll;
  width: 100%;
}

.three-image-carousel {
  width: calc((100% - 2em) * 3 + 1em);
  display: flex;
  // height: calc(100vw - 2em);
  margin: 0 1em 1em;
}

.testSet-header {
  margin: 1em 1em 0;
}

.testSet-checklist {
  margin: 0 1em;
}

.carousel-image {
  background-color: $laz-sky-blue;
  box-sizing: border-box;
  border: 1px solid black;
  height: 100%;
  width: 100%;
  cursor: pointer;
  position: relative;
}

.carousel-image h3 {
  position: absolute;
  top: calc(50% - 4.5em);
  left: 50%;
  transform: translate(-50%, -50%);
  // margin: 1em;
  text-align: center;
}

.space-around {
  justify-content: space-around;
}

.middle-row {
  margin: 0.5em 0;
}

#checklist-h2 {
  margin: 1em 1.75em 1em;
}

.space-between {
  justify-content: space-between;
}

.space-evenly {
  justify-content: space-evenly;
}

.align-start {
  align-items: flex-start;
}

#ldm {
  height: 100%;
  width: auto;
}

.pulse {
  animation: pulse 2s infinite;
}

.pulse2 {
  animation: pulse2 1.5s infinite;
}

@-webkit-keyframes dropDown {
  from {
    opacity: 0;
    max-height: 0
  }

  to {
    opacity: 1;
    max-height: 8em;
  }
}

@keyframes dropDown {
  from {
    opacity: 0;
    max-height: 0
  }

  to {
    opacity: 1;
    max-height: 8em;
  }
}

@-webkit-keyframes shrinkUp {
  from {
    // opacity: 1;
    max-height: 8em
  }

  to {
    // opacity: 0;
    max-height: 0;
  }
}

@keyframes shrinkUp {
  from {
    // opacity: 1;
    max-height: 8em
  }

  to {
    // opacity: 0;
    max-height: 0;
  }
}

@-webkit-keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.2, 1.2, 1.2);
    transform: scale3d(1.2, 1.2, 1.2);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@-webkit-keyframes pulse2 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}

@keyframes pulse2 {
  from {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }

  50% {
    -webkit-transform: scale3d(1.05, 1.05, 1.05);
    transform: scale3d(1.05, 1.05, 1.05);
  }

  to {
    -webkit-transform: scale3d(1, 1, 1);
    transform: scale3d(1, 1, 1);
  }
}


.three-photo-container {
  display: grid;
  grid-template-columns: repeat(3, calc(33.33%));
  // grid-template-rows: calc(33vw - 0.66em);
  grid-template-rows: auto;
}

.test-set-options {
  position: absolute;
  top: 5em;
  width: calc(100% - 2em);
  height: 100%;
  // height: calc(33vw - 0.66em + 2px);
}

.test-set-options .btn-div:hover {
  background-color: rgb(115, 115, 115);
}

.test-set-options > div, .test-set-options > div > div {
  height: 100% !important;
}

.test-set-options > div > div {
  border: 1px solid black;
  background-color: $laz-light-gray;
}

.test-set-options > div > div > p {
  color: black;
}

.test-set-options > div > div:first-child {
  border-right: none;
}

.submit-btn {
  position: fixed;
  left: 50%;
  bottom: 1em;
  transform: translateX(-50%);
  // width: calc(100% - 2em);


}

.album-image {
  width: 100%;
  height: auto;
}

.j > .btn-div {
  background-color: $laz-dark-gray;
  height: 4em;
  transition: 0.3s;
}

.j > .btn-div:hover {
  background-color: darken($laz-dark-gray, 10%);
}

.btn-div {
  background: black;
  // border: 1px solid black;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
}

.test-ordered-div {
  border-top: 1px solid #a5a5a5;
  margin: 0 1em;
}

.physician-contact-div {
  margin: 0 1em;
}

.example-mole-img {
  margin: 0 1em;
  width: calc(100% - 2em);
}

.page-1-header {
  padding-bottom: 3.5em !important;
}

.testing-for-div {
  margin-top: -2.5em;
  padding: 1em;
  background-color: white;
  border: 1px solid #a5a5a5;
  // box-sizing: border-box;
  margin: -2.5em 1em 1em;
  height: 5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  animation: none !important;
  border-left: 10px solid black;
}

.testing-for-div > div:first-child {
  display: flex;
  justify-content: space-between;
}

.testing-for-div img {
  height: 1.5em;
  margin: -7px -7px 0 0;
}

.testing-for-div p:first-child {
  font-size: 12px;
}

.testing-for-div * {
  margin: 0;
}

.vertical-btn {
  width: 100%;
  margin: 5px 1em;
}

.background-gray {
  background-color: $laz-dark-gray;
}

.margin-1em {
  margin: 1em;
}

.btn-div > p {
  color: white;
  margin: 0;
}

.blue {
  background-color: $laz-blue;
  color: white;
  border: 1px solid $laz-blue;
}

.btn-div:hover {
  cursor: pointer;
  background-color: $laz-btn-hover;
}

.blue:hover {
  background-color: darken($laz-blue, 10%);
}

.k .ReactCrop__crop-selection {
  background: radial-gradient(rgba(53, 244, 188, 1), rgba(53, 244, 188, 0.5), rgba(53, 244, 188, 0.2), rgba(53, 244, 188, 0), rgba(53, 244, 188, 0)) !important;
  border: 1px solid white;
  box-shadow: none;
  border-radius: 100px;
  // width: 50px !important;
  // height: 50px !important;
}

.zoomed-in .ReactCrop__crop-selection {
  background: radial-gradient(rgba(53, 244, 188, 1), rgba(53, 244, 188, 0.3), rgba(53, 244, 188, 0.1), rgba(53, 244, 188, 0), rgba(53, 244, 188, 0), rgba(53, 244, 188, 0)) !important;
  border: 1px solid white;
  box-shadow: none;
  border-radius: 100px;
}

.relative {
  position: relative;
}

.upper-left {
  position: absolute;
  top: 0;
  left: 0;
}
.btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.btn-container > .btn-div {
  width: 50%;
}

.image-upload-container .cropper-container {
  // height: 100%;
  // width: 100%;
}

.image-upload-container .image-container {
  // height: 100%;
  // width: 100%;
  padding: 1em 1em 0;
}

.image-upload-container .crop-image {
  // height: 100%;
  // width: 100%;
}

.image-upload-container .crop-image > div:first-child {
  // width: 100%;
}

.image-upload-container .ReactCrop__image {
  // max-height: 100%;
  // width: 100%;
  // height: auto;
}

// .crop-image > div {
//   height: 100%;
// }

.cropper-container {
  // max-width: 100%;
  // max-height: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}

.circle {
  background-color: white;
  height: 3em;
  width: 3em;
  border-radius: 2em;
  border: 1px solid $laz-dark-gray;
}

.circle > img {
  height: 2em;
  width: 2em;
}

.ReactCrop__image, .crop-image {
  // max-width: 100%;
  // max-height: calc(100vh - 150px) !important;
}

.k > .cropper-container {
  // height: 100%;
}

.k .image-container, .k .crop-image {
  height: 100%;
  // width: 100%;
  // max-height: 100%;
}

.k .image-container {
  box-sizing: border-box;
  padding: 10px 0;
}

.k .crop-image > div:first-child {
  height: 100%;
}

.k .crop-image > div > .ReactCrop__image {
  max-height: calc(100vh - 7em) !important;
  height: 699px;
  // height: 100%;
  // width: auto;
}

.zoomed-in .ReactCrop__image {
  filter: blur(2px);
}

// .zoomed-in > .cropper-container, .zoomed-in > .cropper-container > .image-container, .zoomed-in > .cropper-container > .image-container > .crop-image {
//   height: 100%;
//   // width: 100%;
//   // max-height: 100%;
// }

// .zoomed-in > .cropper-container > .image-container {
//   box-sizing: border-box;
//   padding: 10px 0;
// }

// .zoomed-in > .cropper-container > .image-container > .crop-image > div:first-child {
//   height: 100%;
// }

// .zoomed-in > .cropper-container > .image-container > .crop-image > div > .ReactCrop__image {
//   max-height: 100% !important;
//   height: 100%;
//   // width: auto;
// }



// .zoomed-in, .zoomed-in > div, .zoomed-in > div > div:first-child {
//   width: 100%;
//   height: 100%;
// }

// .zoomed-in > .react-crop {
//   position: static !important;
// }

// .zoomed-in > div {
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .zoomed-in > div > div {
//   max-width: 600px;
// }

// .zoomed-in > div > div > img {
//   position: absolute;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);

//   width: 100%;  // reversing width/auto values causes max update error
//   height: auto;
// }

.image-upload-container > div > div > div.crop-image {
  // height: 100%; // crashed images that aren't full height
}

.image-upload-container > div > div > img {
  // width: auto;
  // margin: 5.5em 0 0;
  // height: auto !important;
  // object-fit: contain;
}

.image-upload-container img {
  // object-fit: contain;
}

.image-upload-container .crop-image {
  // height: 100%;
  // max-height: 100%;
}

.image-upload-container .crop-image > div:first-child {
  // overflow: scroll;
  // height: 100%;
}

.image-container > img {
  max-width: 100%;
  max-height: 100%;
}

.image-upload-container > div > div > .crop-image > div {
}

.image-upload-container {
  height: 100%;
  display: grid;
  grid-template-rows: 6em calc(100% - 12em) 6em;
  // grid-template-rows: 6em max-content 6em;
  overflow: scroll;
}

.flip-div, .x-div {
  z-index: 2;
}

.flip-div {
  height: 3em;
  width: 3em;
  // box-sizing: border-box;
  padding: 0.5em;
}
.x-div {
  height: 4em;
  width: 4em;
}

.cancel-circle {
  border: 1px solid white;
  border-radius: 3em;
  padding: 0.25em;
}

.mole-img {
  width: 100%;
  height: 100%;
}

.cancel-circle2 {
  border: 1px solid black;
  border-radius: 3em;
  padding: 0.25em;
}

.background-white {
  background: white;
}

.cancel-circle > img, .cancel-circle2 > img, .cancel-circle3 > img {
  height: 1.5em;
  width: 1.5em;
}

.cancel-circle3 > img {
  height: 3em;
  width: 3em;
}

.flip-div:hover {
  cursor: pointer;
}

.border-white {
  border: 1px solid white;
}

.flip-div > img {
  width: 2em;
  height: 2em;
}

.flip-div > p {
  text-align: center;
  font-size: 0.5em;
}

#notes-container {
  width: calc(100% - 2em);
  margin: 1em 1em 0;
}

.ReactCrop__drag-handle {
  width: 20px !important;
  height: 20px !important;
  margin: 0 !important;
}

#notes-container > textarea {
  border-radius: 0 !important;
  height: 5em;
  width: 100%;
  border: 1px solid black;
  padding: 0.5em;
  box-sizing: border-box;
  font-size: 1em;
}

#notes-container > textarea:focus {
  outline-color: $laz-sky-blue;
}

.body-part-container {
  background: black !important;
  position: relative;
  display: grid;
  height: 100%;
  width: 100%;
  grid-template-columns: 1fr 6fr 1fr;
  grid-template-rows: calc(100% - 5em) 5em;
  grid-template-areas:
  "g g g"
  ". j .";
}

.f {
  grid-area: f;
}

.g {
  z-index: 2;
  grid-area: g;
}

.g > div:first-child {
  margin: 1em;
}

.g p {
  z-index: 3;
}

.h {
  grid-area: h;
}

.r {
  top: 25%;
  position: absolute;
  right: 2em;
  z-index: 2;
}

.l {
  top: 25%;
  position: absolute;
  left: 2em;
  z-index: 2;
}

.k {
  grid-area: k;
  position: absolute;
  // top: 30%;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  height: calc(100% - 5em);
  // height: calc(480 * 1.45667);
  width: 100%;
}

.i {
  grid-area: i;
  z-index: 2;
  position: absolute;
  bottom: 6em;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}

.i p {
  z-index: 2;
}

.j {
  grid-area: j;
  z-index: 2;
}

.j * {
  z-index: 2;
}

.width-90 {
  width: 90%;
}

.two-btn-container {
  display: flex;
}

.width-50 {
  width: 50%;
  box-sizing: border-box;
}

.margin-top-0-5 {
  margin: 0.5em 0 0 0;
}

.modal {
  border: 1px solid black;
  z-index: 3;
  background: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  height: 300px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.modal-content {
  width: 90%;
}

.modal-content > div {
  margin-bottom: 5px;
}

.z3 {
  z-index: 3;
}

#grey-screen {
  background-color: rgba(150, 150, 150, 0.5);
  height: 100%;
  width: 100%;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0)
}

.results-container {
  padding: 1em;
  box-sizing: border-box;
  width: 100%;
  display: grid;
  grid-template-columns: 6em auto;
  grid-template-rows: auto auto;
  grid-template-areas:
    "m n"
    "m o"
}

.m {
  grid-area: m;
}

.n {
  grid-area: n;
}

.o {
  grid-area: o;
}

.green-circle {
  border: 2px solid rgb(0, 212, 77);
  border-radius: 4em;
  height: 4em;
  width: 4em;
}

.circle-container {
  width: 5em;
  height: 5em;
}

.green-circle > img {
  height: 4em;
  width: 4em;
}

@-webkit-keyframes slideInDown {
  from {
    -webkit-transform: translate(-100%, -50%);
    transform: translate(-100%, -50%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0, -50%);
    transform: translate(0, -50%);
    opacity: 1;
  }
}

@keyframes slideInDown {
  from {
    -webkit-transform: translate(-50%, -100%);
    transform: translate(-50%, -100%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    opacity: 1;
  }
}

.slideInDown {
  -webkit-animation-name: slideInDown;
  animation-name: slideInDown;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}


@-webkit-keyframes slideInUp {
  from {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

@keyframes slideInUp {
  from {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    opacity: 0;
  }

  to {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
}

.slideInUp {
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
  opacity: 0;
  -webkit-animation-name: slideInUp;
  animation-name: slideInUp;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

.slideOutDown {
  -webkit-animation-name: slideOutDown;
  animation-name: slideOutDown;
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@-webkit-keyframes slideOutDown {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }

  to {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    opacity: 0;
  }
}

@keyframes slideOutDown {
  from {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
    opacity: 1;
  }
  to {
    -webkit-transform: translate(0, 100%);
    transform: translate(0, 100%);
    opacity: 0;
  }
}

@keyframes loadBar {
  from {
    width: 0;
  }
  to {
    width: 95%;
  }
}

@-webkit-keyframes loadBar {
  from {
    width: 0;
  }
  to {
    width: 95%;
  }
}

.loading-screen-page {
  height: 100%;
  width: 100%;
  padding: 1em;
  box-sizing: border-box;
}

.loading-bar-container {
  height: 5em;
  width: 100%;
  background-color: black;
}

.loading-bar {
  height: 100%;
  background-color: rgb(75, 227, 196);
  animation: loadBar 20s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(0, 0, 0, 1.0)
}

.block {
  margin-left: -15px;
}

.margin-0 {
  margin: 0;
}

// .x-box {
//   position: absolute;
//   top: 0;
//   right: 0;
//   width: 2em;
//   height: 2em;
//   border: 1px solid black;
// }

// .x-box > img {
//   width: 2em;
//   height: 2em;
// }

.underline {
  text-decoration: underline;
}

.full-modal {
  padding: 1em;
  box-sizing: border-box;
  z-index: 4;
  background-color: rgba(255, 255, 255, 0.9);
  display: grid;
  grid-template-rows: auto auto 9em;
  position: fixed;
  top: 2em;
  // left: 50%;
  // transform: translateX(-50%);
  height: calc(100% - 2em);
  width: 100%;
  max-width: 480px;
  border-radius: 40px 40px 0 0;
  border-top: 1px solid black;

}

#pricing-modal {
  grid-template-rows: min-content min-content auto min-content;
  background-color: rgba(255, 255, 255, 0.97);
}

.pricing-items > div {
  border: 1px solid black;
  padding: 1em;
  > div {
    margin-left: -7px;
  }
}

.align-items-center {
  align-items: center;
}

// .pricing-items > div:last-child {
//   border-top: none;
// }

.pricing-items h1 {
  color: $laz-green;
  margin: 0.5rem 0
}

.pricing-items p {
  margin: 0 0 0.5rem;
  font-size: 1.5em;
}

#pricing-modal > div > p {
  font-size: 1.25em;
}

.cart-modal {
  background-color: white;
  grid-template-rows: min-content auto auto min-content !important;
}

.StripeElement {
  width: 100% !important;
  box-sizing: border-box;
  margin: 10px 0;
}

.stripe-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.vertical-btn-container {
  margin: 0 1em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.vertical-btn-container > div:first-child {
  margin-top: 0;
}

.vertical-btn-container > div:nth-child(2) {
  margin-bottom: 0;
}

.full-modal > .vertical-btn-container {
  margin: 0;
}

.select-photos {
  height: 8em;
  border: 2px dashed $laz-charcoal;
  background: $laz-gray;
  margin: 0 1em 1em;
}

.black {
  color: black !important;
}

#no-sides {
  border-left: none;
  border-right: none;
}

.photo-collection {
  margin-bottom: 6em;
}

.photo-collection > .test-set {
  position: relative;
}

.photo-collection > .test-set > div {
  margin: 0 1em;
}

.drop-down {
  overflow: hidden;
  animation: dropDown 0.3s;
  animation-fill-mode: forwards;
}

.shrink-up {
  overflow: hidden;
  animation: shrinkUp 0.3s;
  animation-fill-mode: forwards;
}

// Short Mobile screens

@media only screen and (max-height: 600px) {

  #instructions {
    font-size: 14px;
  }
  .checklist-header-div {
    font-size: 12px;
  }

  #checklist-container h2 {
    font-size: 1.25em;
    margin: 1em 2.25em 0.25em
  }
  
  .check-item > p {
    margin: 0.5em 0;
  }
}

.flipped {
  background-color: black;
  transform: rotate(90deg);
  transform-origin: left top;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.flipped > h2 {
  color: white;
  // transform: rotate(-90deg);
  // transform-origin: left top;
}

#loading-container {
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fafafa;
}

#loading-container2 {
  z-index: 2;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
  background-color: white;
  border-radius: 40px 40px 0 0;
}

.box {
  z-index: 5;
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  overflow: hidden;
}

.box .b {
  border-radius: 50%;
  border-left: 4px solid;
  border-right: 4px solid;
  border-top: 4px solid transparent !important;
  border-bottom: 4px solid transparent !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ro 2s infinite;
}

.box .b1 {
  border-color: $button;
  width: 120px;
  height: 120px;
}

.box .b2 {
  border-color: $percent;
  width: 100px;
  height: 100px;
  animation-delay: 0.2s;
}

.box .b3 {
  border-color: $button;
  width: 80px;
  height: 80px;
  animation-delay: 0.4s;
}

.box .b4 {
  border-color: $percent;
  width: 60px;
  height: 60px;
  animation-delay: 0.6s;
}

@keyframes ro {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(-180deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
}

@media only screen and (max-width: 480px) {
  .App {
    max-width: 100vw !important;
  }

  .ReactCrop__drag-handle {
    width: 40px !important;
    height: 40px !important;
    margin: 0 !important;
  }

  // .k {
  //   height: calc(100vw * 1.45667);
  //   // top: 30%;
  //   transform: translate(-50%, 0);
  // }
  .k {
    display: flex;
    align-items: center;
  }
  .k .ReactCrop__image {
    height: calc(100vw * 1.45667) !important; // h/w ratio of image
  }
}

@media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
  #root {
    height: 100vw;
    width: 100vh;
  }
  .k .ReactCrop__image {
    height: calc(100vh * 1.45667) !important;
  }
}
